import React, { useState, useEffect } from "react";
import CircleImage from "../../static/circle.png";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Tooltip,
} from "recharts";
import Loadingcomponent from "../../Components/LoadingComponent.js";
import PieChartGraph from "../../Components/PieChartGraph.js";

export var allLocations = [];

const DashBoardScreen = () => {
  const [loading, setLoading] = useState(true);

  const [chargers, setChargers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [connectorTypes, setConnectorTypes] = useState();
  const [bookings, setBookings] = useState([]);
  const [completedBookingsCount, setCompletedBookingscount] = useState();
  const [totalUnitsUsed, setTotalUnitsUsed] = useState();
  const [greenkms, setGreenkms] = useState();
  const [users, setUsers] = useState();
  const [usersCount, setUsersCount] = useState();
  const [chargersNames, setChargersNames] = useState();
  const [mostUsedChargers, setMostUsedChargers] = useState();
  const [mostActiveUsers, setMostActiveUsers] = useState();
  const [chargersDetails, setChargersDetails] = useState();
  const [pieChartData, setPieChartData] = useState();

  const [lineChartData, setLineChartData] = useState();

  const getDashboardDetails = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/admin/dashboard/details`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        const bookings = res?.data?.bookings;
        setUsersCount(res?.data?.usersCount);
        setLineChartData(res?.data?.monthyData);
        setBookings(bookings);
        setCompletedBookingscount(res?.data?.chargingSessions);
        setTotalUnitsUsed(res?.data?.totalUnitsConsumed);
        setGreenkms((res?.data?.totalUnitsConsumed * 7.5).toFixed(0));
        setChargersNames(res?.data?.chargersName);
        // getMaximumLatestUsedchargers(bookings);
        setChargersDetails(res?.data?.chargers);
        setChargers(res?.data?.chargers);
        setConnectorTypes(res?.data?.connectorTypes);
        setLocations(res?.data?.locations);
        allLocations = res?.data?.locations;
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getMaximumLatestUsedchargers = async (data) => {
    try {
      setLoading(true);
      // Group chargers by charger name and calculate total UnitsConsumed
      const chargersMap = new Map();
      const usersMap = new Map();

      data.forEach((item) => {
        const chargerName = item.chargerName;
        const userName = item.userName;

        const unitsConsumed = parseFloat(item.UnitsConsumed);

        if (!isNaN(unitsConsumed)) {
          if (usersMap.has(userName)) {
            usersMap.set(userName, usersMap.get(userName) + unitsConsumed);
          } else {
            usersMap.set(userName, unitsConsumed);
          }
          if (chargersMap.has(chargerName)) {
            chargersMap.set(
              chargerName,
              chargersMap.get(chargerName) + unitsConsumed
            );
          } else {
            chargersMap.set(chargerName, unitsConsumed);
          }
        }
      });

      // Convert the Map to an array of charger objects
      const chargersArray = Array.from(
        chargersMap,
        ([chargerName, totalUnitsConsumed]) => ({
          chargerName,
          totalUnitsConsumed: totalUnitsConsumed.toFixed(2), // Format to two decimal places
        })
      );

      const usersArray = Array.from(
        usersMap,
        ([userName, totalUnitsConsumed]) => ({
          userName,
          totalUnitsConsumed: totalUnitsConsumed.toFixed(2), // Format to two decimal places
        })
      );

      // Sort the chargers by total UnitsConsumed in descending order
      chargersArray.sort((a, b) => b.totalUnitsConsumed - a.totalUnitsConsumed);
      usersArray.sort((a, b) => b.totalUnitsConsumed - a.totalUnitsConsumed);

      // Take the top 5 chargers
      const top5Chargers = chargersArray.slice(0, 8);
      const top5users = usersArray.slice(0, 5);

      // Log the top 5 chargers with their total UnitsConsumed
      // console.log(top5Chargers);
      setMostUsedChargers(top5Chargers);
      setMostActiveUsers(top5users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);

  useEffect(() => {
    SetPieChartDatafromMostActiveUsers();
  }, [mostActiveUsers]);

  const SetPieChartDatafromMostActiveUsers = () => {
    try {
      const labels = [];
      const DatasetData = [];
      mostActiveUsers?.map((user) => {
        if (!user?.userName) {
          labels.push("User");
        } else {
          labels.push(user?.userName);
        }
        DatasetData.push(user?.totalUnitsConsumed);
      });
      const data = {
        labels: labels,
        datasets: [
          {
            label: ` Units Consumed `,
            data: DatasetData,
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: ["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"],
            borderWidth: 4,
          },
        ],
      };
      setPieChartData(data);
      // console.log(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return loading ? (
    <Loadingcomponent />
  ) : (
    <div>
      {/* Analysis Modals For dashboard */}
      <div className="mt-0 flex justify-center flex-wrap">
        {/* otal Chargers Installed */}
        <div
          className="shadow-2xl"
          style={{
            height: 120,
            width: 230,
            background: `linear-gradient(260deg, #F13D00 0%, rgba(0, 0, 0, 0)190%)`,
            margin: 20,
          }}
        >
          <img
            alt="Circle"
            style={{
              position: "absolute",
              marginLeft: 120,
              height: 120,
              width: 120,
            }}
            src={CircleImage}
          />
          <div className="mt-6">
            <p className=" text-center" style={{ color: "white" }}>
              Total Chargers
            </p>
            <p
              className="m-5 text-center text-4xl font-bold"
              style={{ color: "white" }}
            >
              {chargers < 10 ? "0" + chargers : chargers}
            </p>
          </div>
        </div>

        <div
          className="shadow-2xl"
          style={{
            height: 120,
            width: 230,
            background: `linear-gradient(260deg, #FF002B 0%, rgba(0, 0, 0, 0)190%)`,
            margin: 20,
          }}
        >
          <img
            alt="Circle"
            style={{
              position: "absolute",
              marginLeft: 120,
              height: 120,
              width: 120,
            }}
            src={CircleImage}
          />
          <div className="mt-6">
            <p className=" text-center" style={{ color: "white" }}>
              Total Users
            </p>
            <p
              className="m-5 text-center text-4xl font-bold"
              style={{ color: "white" }}
            >
              {usersCount < 10 ? "0" + usersCount : usersCount}
            </p>
          </div>
        </div>

        <div
          className="shadow-2xl"
          style={{
            height: 120,
            width: 230,
            background: `linear-gradient(260deg, #A400DA 0%, rgba(0, 0, 0, 0)190%)`,
            margin: 20,
          }}
        >
          <img
            alt="Circle"
            style={{
              position: "absolute",
              marginLeft: 120,
              height: 120,
              width: 120,
            }}
            src={CircleImage}
          />
          <div className="mt-6">
            <p className=" text-center" style={{ color: "white" }}>
              Total Charging sessions
            </p>
            <p
              className="m-5 text-center text-4xl font-bold"
              style={{ color: "white" }}
            >
              {completedBookingsCount < 10
                ? "0" + completedBookingsCount
                : completedBookingsCount}
            </p>
          </div>
        </div>

        <div
          className="shadow-2xl"
          style={{
            height: 120,
            width: 230,
            background: `linear-gradient(260deg, #04A76C 0%, rgba(0, 0, 0, 0)190%)`,
            margin: 20,
          }}
        >
          <img
            alt="Circle"
            style={{
              position: "absolute",
              marginLeft: 120,
              height: 120,
              width: 120,
            }}
            src={CircleImage}
          />
          <div className="mt-6">
            <p className=" text-center" style={{ color: "white" }}>
              Green Kilometers
            </p>
            <p
              className="m-5 text-center text-4xl font-bold"
              style={{ color: "white" }}
            >
              {parseFloat(greenkms / 1000).toFixed(2)} K
              {/* <span className="text-lg">kms</span> */}
            </p>
          </div>
        </div>

        <div
          className="shadow-2xl"
          style={{
            height: 120,
            width: 230,
            background: `linear-gradient(260deg, #0013FF 0%, rgba(0, 0, 0, 0)190%)`,
            margin: 20,
          }}
        >
          <img
            alt="Circle"
            style={{
              position: "absolute",
              marginLeft: 120,
              height: 120,
              width: 120,
            }}
            src={CircleImage}
          />
          <div className="mt-6">
            <p className=" text-center" style={{ color: "white" }}>
              Total Units sold
            </p>
            <p
              className="m-5 text-center text-4xl font-bold"
              style={{ color: "white" }}
            >
              {parseFloat(totalUnitsUsed / 1000).toFixed(2)} K
              {/* <span className="text-lg">kWh</span> */}
            </p>
          </div>
        </div>
      </div>

      {/* Graph Here for details */}
      <div className="flex bg-white mt-10 justify-around flex-col sm:flex-row">
        <div className="w-6/12 ml-5 sm:ml-0 mr-5 sm:mr-0 shadow-xl ">
          <p style={{ textAlign: "center" }}>Total Energy Units Sold</p>
          <ResponsiveContainer className="" width={"100%"} aspect={2}>
            <LineChart data={lineChartData} margin={{ right: 0 }}>
              <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
              <XAxis dataKey="month" interval={"preserveStartEnd"} />
              <YAxis />
              {/* <Legend />  */}
              <Tooltip />
              <Line
                type="monotone"
                dataKey="totalUnitsConsumed"
                stroke="green"
                activeDot={{ r: 10 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="w-6/12 ml-5 mr-5 shadow-xl ">
          <p style={{ textAlign: "center" }}>Revenue (in Rs)</p>

          <ResponsiveContainer className="" width="100%" aspect={2}>
            <BarChart width={600} height={300} data={lineChartData}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="totalRevenue" barSize={30} fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/*  */}
      {/* <div className="flex justify-around mt-10 flex-col sm:flex-row">
        <div
          className=" shadow-2xl w-full h-30"
        >
          <div
            className=" pl-2"
            style={{
              background: `linear-gradient(10deg, blue 0%, rgba(0, 0, 0, 0)200%)`,
            }}
          >
            <p className="text-white font-bold pt-2 pb-2">
              Top ElectricWeb Chargers{" "}
              <span className="font-semibold">( last 30 days )</span>
            </p>
          </div>
          <div className="m-4 flex justify-between">
            <p className="" style={{ width: "45%" }}>
              Location
            </p>
            <p style={{ width: "30%", textAlign: "center" }}>Charger Name</p>
            <p style={{ width: "20%", textAlign: "center" }}>Units Sold</p>
          </div>
          {mostUsedChargers &&
            mostUsedChargers?.map((charger) => {
              return (
                <div className="m-2 flex justify-between ">
                  <p
                    className=""
                    style={{
                      width: "45%",
                      color: "grey",
                      fontSize: 13,
                      lineHeight: 1,
                      height: 14,
                      overflow: "hidden",
                    }}
                  >
                    {chargersDetails?.map((c) => {
                      if (charger?.chargerName === c?.chargerName) {
                        return locations?.map((location) => {
                          if (location?._id === c?.address) {
                            return location?.address;
                          }
                        });
                      } else {
                        return;
                      }
                    })}
                  </p>
                  <p
                    style={{
                      width: "30%",
                      textAlign: "center",
                      color: "grey",
                      fontSize: 13,
                    }}
                  >
                    {charger?.chargerName}
                  </p>
                  <p
                    style={{
                      width: "20%",
                      textAlign: "center",
                      color: "grey",
                      fontSize: 13,
                    }}
                  >
                    {charger?.totalUnitsConsumed} kWh
                  </p>
                </div>
              );
            })}
        </div>
        <div className="ml-5">
          <p className="text-center bg-slate-800 text-white mt-5 sm:mt-0">
            Top Users <span className="font-light">( last 30 days )</span>
          </p>
          {pieChartData ? <PieChartGraph data={pieChartData} /> : ""}
        </div>
      </div> */}
    </div>
  );
};

export default DashBoardScreen;
