import React, { useState, useEffect } from "react";
import { Select, Modal, Button, message } from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Table } from "antd";
import LoadingComponent from "../../Components/LoadingComponent";
import { websocketConnectionUrl } from "../../static/BaseUrl.js";
const Option = Select.Option;

const OngoingBookingsScreen = () => {
  const [bookings, setBookings] = useState([]);
  const [formatedBookings, setformatedBookings] = useState([]);
  const [chargerStatuses, setChargerStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState();
  const [chargersNames, setChargersNames] = useState();

  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [filterSet, setfilterSet] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState();

  const [warningModalOpen, setWarningModalOpen] = useState();
  const [selectedOngoingBooking, setSelectedOngoingBooking] = useState();
  const [unlockConnectorModalOpen, setUnlockConnectorModalOpen] = useState();
  const [selectedConnectorId, setSelectedConnectorId] = useState();

  const [wsGlobal, setWsGlobal] = React.useState("");

  useEffect(() => {
    var ws = new WebSocket(
      websocketConnectionUrl
    );
    ws.onopen = () => {
      setInterval(() => {
        ws.send({
          From: "AdminDashboard",
        });
      }, 10000);

      ws.onmessage = (e) => {
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger[0], charger[1]);
            });
            if (chargersMap != chargerStatuses) {
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BaseUrl}/get/admin/ongoing/bookings/details`,
        {
          token: localStorage.getItem("token"),
        }
      );
      if (res?.data?.success) {
        setUsers(res?.data?.users);
        setBookings(res?.data?.bookings);
        formatBookingData(res?.data?.users, res?.data?.bookings);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  var userFetched = false;
  useEffect(() => {
    if (chargerStatuses && !userFetched && !unchangedFormatedTransactions) {
      getUsers();
    }
  }, [chargerStatuses]);

  const columns = [
    {
      title: "BookingId",
      dataIndex: "transactionId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "Name",
      dataIndex: "userName",
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
    },
    {
      title: "IdTag",
      dataIndex: "idTag",
    },
    {
      title: "Charger Name",
      dataIndex: "chargerName",
    },
    {
      title: "Start Time",
      dataIndex: `startTime`,
    },
    {
      title: "Remote Stop ",
      dataIndex: "settedTimePeriod",
    },
    {
      title: "Blocked Amount",
      dataIndex: "blockedAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.blockedAmount - b.blockedAmount,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) =>
        record.status === "Charging" || record.status === "SuspendedEV" ? (
          <a
            onClick={() => {
              setWarningModalOpen(true);
              setSelectedOngoingBooking(record);
            }}
            className="ml-4"
            href="javascript:;"
          >
            <i
              style={{ color: "blue", fontSize: 20 }}
              class="fa-solid fa-circle-stop"
            ></i>
          </a>
        ) : record.status === "SuspendedEV" ? (
          <a
            onClick={() => {
              setUnlockConnectorModalOpen(true);
              setSelectedOngoingBooking(record);
            }}
            className="ml-4"
            href="javascript:;"
          >
            <i
              style={{ color: "blue", fontSize: 20 }}
              class="fa-solid fa-unlock-keyhole"
            ></i>
          </a>
        ) : record.status === "Preparing" ? (
          <a className="ml-4">
            <i
              style={{ color: "blue", fontSize: 20 }}
              class="fa-solid fa-circle-play"
            ></i>
          </a>
        ) : (
          "No Action"
        ),
    },
  ];

  // Remote Transactions
  const handleRemoteStopTransaction = async (socket) => {
    setLoading(true);
    try {
      let sendData = {
        From: `BD8746G645VWU83`,
        To: `${selectedOngoingBooking?.chargerName}`,
        message: [
          2,
          "RemoteStopTransaction",
          "RemoteStopTransaction",
          {
            transactionId: Number(selectedOngoingBooking?.transactionId),
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
      setLoading(false);
      // Update Booking to closing State, Blocked Amount = Zero And Updated WalletAmount = walletAmount-(TentativePrice-BlockedAmount) to FROM WEBSOCKET SERVER to Backend
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRemoteStartTransaction = async (socket) => {
    setLoading(true);
    try {
      let sendData = {
        From: `BD8746G645VWU83`,
        To: `${selectedOngoingBooking?.chargerName}`,
        message: [
          2,
          "RemoteStartTransaction",
          {
            transactionId: selectedOngoingBooking?.transactionId,
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
      // Update Booking to closing State, Blocked Amount = Zero And Updated WalletAmount = walletAmount-(TentativePrice-BlockedAmount) to FROM WEBSOCKET SERVER to Backend
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUnlockConnector = async (socket) => {
    try {
      let sendData = {
        From: `BD8746G645VWU83`,
        To: `${selectedOngoingBooking?.chargerName}`,
        message: [
          2,
          `UnlockConnectorReqByAdmin`,
          "UnlockConnectorRequest",
          {
            connectorId: selectedConnectorId,
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  const formatBookingData = async (users, bookings) => {
    setLoading(true);
    try {
      let array = bookings;
      let chargersNames0 = [];
      await array?.map((booking) => {
        if (!chargersNames0?.includes(booking?.chargerName)) {
          chargersNames0.push(booking?.chargerName);
        }
        if (booking?.startTime) {
          booking.startTime =
            booking?.startTime?.split(" ")[1] +
            "/" +
            booking?.startTime?.split(" ")[2] +
            "/" +
            booking?.startTime?.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking?.startTime?.split(" ")[4]);
          booking.status = chargerStatuses?.get(booking?.chargerName);
          const details = users?.find((user) => user?.idTag === booking?.idTag);
          booking.phone = details?.phone;
          if (booking?.chargingBased === "time") {
            booking.settedTimePeriod = `${booking?.settedTimePeriod / 60} hrs`;
          } else if (booking?.chargingBased === "units") {
            booking.settedTimePeriod = `${booking?.unitsSetted} kWh`;
          } else if (booking?.chargingBased === "amount") {
            booking.settedTimePeriod = `Rs ${booking?.blockedAmount}`;
          } else {
            booking.settedTimePeriod = `${booking?.settedTimePeriod / 60} hrs`;
          }
        }
      });
      setChargersNames(chargersNames0);
      setUnchangedFormatedTransactions(array);
      setformatedBookings(array);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      // getUsers();
    }
  };

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const filterData = async () => {
    try {
      setLoading(true);
      if (selectedCharger) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((charger) => {
          if (charger?.chargerName === selectedCharger) {
            resultedArray.push(charger);
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Confirm Stop Transaction Modal */}
      <Modal
        // title="Confirm"
        centered
        open={warningModalOpen}
        onCancel={() => setWarningModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setWarningModalOpen(false)}>Cancel</Button>,
          <Button
            danger
            onClick={() => {
              setWarningModalOpen(false);
              handleRemoteStopTransaction(wsGlobal);
            }}
          >
            Stop
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Stop {selectedOngoingBooking?.userName}'s session ?
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>

      {/* Confirm Unlock Connector Modal */}
      <Modal
        // title="Confirm"
        centered
        open={unlockConnectorModalOpen}
        onCancel={() => setUnlockConnectorModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setUnlockConnectorModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            onClick={() => {
              setUnlockConnectorModalOpen(false);
              handleUnlockConnector(wsGlobal);
            }}
          >
            Unlock
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Unlock connector of {selectedOngoingBooking?.userName}'s car ?
        </p>
        <div className="flex justify-center">
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select connector id"
            optionFilterProp="children"
            onChange={(value) => setSelectedConnectorId(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
          </Select>
        </div>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ongoing Bookings
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col sm:flex-row justify-between">
        {/* <div className="flex">
          <p style={{marginTop:2}}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 200 }}
            onChange={onChange}
          />
        </div> */}
        {/* State */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Charger</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select charger"
            optionFilterProp="children"
            onChange={(value) => setSelectedCharger(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {chargersNames?.map(function (key) {
              return <Option value={key}>{key}</Option>;
            })}
          </Select>
        </div>
        {/* PinCode */}
        {/* <div className="flex">
          <p style={{marginTop:2}}>Pin code</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={handlePinCodeChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="tom">Tom</Option>
          </Select>
        </div> */}
        <div className="flex justify-center">
          <a
            onClick={() => {
              filterData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getUsers();

            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getUsers();

              setfilterSet(false);
              setSelectedCharger(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto" style={{ maxHeight: 500 }}>
        <Table columns={columns} dataSource={formatedBookings} />
      </div>
    </div>
  );
};
export default OngoingBookingsScreen;
