import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Set Charging Profile
      </p>
      <p className="text-slate-600">
        A Central System can send a SetChargingProfile.req to a Charge Point, to
        set a charging profile, in the following situations: <br />
        • At the start of a transaction to set the charging profile for the
        transaction; <br />
        • In a RemoteStartTransaction.req sent to a Charge Point <br />
        • During a transaction to change the active profile for the transaction;
        <br />• Outside the context of a transaction as a separate message to
        set a charging profile to a local controller, Charge Point, or a default
        charging profile to a connector.
      </p>
    </div>
  );
};

export default Screen;
