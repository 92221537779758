import React, { useState, useEffect } from "react";
import { Select } from "antd";
import LoadingComponent from "../../Components/LoadingComponent";
import { websocketConnectionUrl } from "../../static/BaseUrl.js";

const Option = Select.Option;

function ShowAllChargersScree() {
  const [wsGlobal, setWsGlobal] = React.useState("");
  const [chargersStatus, setChargerStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const [availableChargers, setAvailableChargers] = useState();
  const [unavailableChargers, setUnavailableChargers] = useState();
  const [finishingChargers, setFinishingChargers] = useState();
  const [suspendedEvChargers, setSuspendedEvChargers] = useState();
  const [chargingChargers, setChargingChargers] = useState();
  const [othersChargers, setOthersChargers] = useState();
  const [chargersNames, setChargersNames] = useState();
  const [selectedCharger, setSelectedCharger] = useState();

  useEffect(() => {
    connectConnection();
  }, []);

  const connectConnection = async () => {
    try {
      setLoading(true);
      var ws = new WebSocket(websocketConnectionUrl);
      ws.onopen = () => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        setInterval(() => {
          let sendData = {
            From: "HeartBeatRequest",
          };
          ws.send(JSON.stringify(sendData));
        }, 10000);

        ws.onmessage = (e) => {
          try {
            if (e.data.split("*")[0] === "StatusNotification ") {
              const arrayString = e.data.split("*")[1];
              const array = JSON.parse(arrayString);
              // const mapFromJSON = array.reduce((map, [key, value]) => {
              //   map.set(key, value);
              //   return map;
              // }, new Map());
              setChargerStatus(array);
            }
            setLoading(false);
          } catch (error) {}
        };

        ws.onclose = (e) => {
          ws.close();
        };
      };
      setWsGlobal(ws);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const availableChargers0 = [];
    const unavailableChargers0 = [];
    const finishingChargers0 = [];
    const suspendedEvChargers0 = [];
    const chargingChargers0 = [];
    const othersChargers0 = [];
    if (chargersStatus) {
      chargersStatus?.map((charger) => {
        if (charger?.[1] === "Available") {
          availableChargers0.push(charger);
        } else if (
          charger?.[1] === "Unavailable" ||
          charger?.[1] === "Faulted"
        ) {
          unavailableChargers0.push(charger);
        } else if (charger?.[1] === "Finishing") {
          finishingChargers0.push(charger);
        } else if (charger?.[1] === "SuspendedEV") {
          suspendedEvChargers0.push(charger);
        } else if (charger?.[1] === "Charging") {
          chargingChargers0.push(charger);
        } else {
          othersChargers0.push(charger);
        }
      });
      setAvailableChargers(availableChargers0);
      setUnavailableChargers(unavailableChargers0);
      setFinishingChargers(finishingChargers0);
      setSuspendedEvChargers(suspendedEvChargers0);
      setChargingChargers(chargingChargers0);
      setOthersChargers(othersChargers0);
    }
  }, [chargersStatus]);

  return loading ? (
    <LoadingComponent />
  ) : (
    <>
      <div className="flex justify-around mb-2 flex-wrap border-2 rounded py-2 shadow-lg">
        <a
          onClick={() => {
            connectConnection();
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {/*
        <div className="flex">
          <Select
            className=""
            showSearch
            style={{ width: 200 }}
            placeholder="Select charger"
            optionFilterProp="children"
            onChange={(value) => setSelectedCharger(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {chargersStatus?.map(function (key) {
              return (
                <Option value={key?.[0]}>{key?.[0]}</Option>
              );
            })}
          </Select>
        </div> */}

        <p className="font-bold text-gray-700 mt-1">
          Total Chargers :{" "}
          {othersChargers?.length +
            availableChargers?.length +
            unavailableChargers?.length +
            suspendedEvChargers?.length +
            finishingChargers?.length +
            chargingChargers?.length}
        </p>
        <p className="font-bold text-green-700 mt-1">
          Available : {availableChargers?.length}
        </p>
        <p className="font-bold text-green-700 mt-1">
          On charging : {chargingChargers?.length}
        </p>
        <p className="font-bold text-blue-700 mt-1">
          SuspendedEV : {suspendedEvChargers?.length}
        </p>
        <p className="font-bold text-blue-700 mt-1">
          Finishing : {finishingChargers?.length}
        </p>
        <p className="font-bold text-red-700 mt-1">
          Unavailable : {unavailableChargers?.length}
        </p>
      </div>

      <div className=" flex justify-center flex-wrap">
        {othersChargers
          ? othersChargers?.map((charger) => {
              return (
                <>
                  <div
                    className="flex border-2 m-3 p-2 rounded-md shadow-xl"
                    style={{
                      borderColor:
                        charger?.[1] === "Charging"
                          ? "#1ff702"
                          : charger?.[1] === "Unavailable" ||
                            charger?.[1] === "Faulted"
                          ? "red"
                          : charger?.[1] === "SuspendedEV"
                          ? "#1b9bd1"
                          : "",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: 50, width: 50 }}
                        alt="Charger"
                        src="https://thumbs.dreamstime.com/b/electric-vehicle-charging-station-public-space-usage-clipping-path-available-68138907.jpg"
                      />
                    </div>
                    <div>
                      <p className="font-bold">Charger : {charger?.[0]}</p>
                      <p className="text-sm font-semibold">
                        Status :{" "}
                        <span
                          style={{
                            color:
                              charger?.[1] === "Charging"
                                ? "green"
                                : charger?.[1] === "Unavailable" ||
                                  charger?.[1] === "Faulted"
                                ? "red"
                                : charger?.[1] === "SuspendedEV"
                                ? "#1b9bd1"
                                : "",
                          }}
                        >
                          {charger?.[1]}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
        {chargingChargers
          ? chargingChargers?.map((charger) => {
              return (
                <>
                  <div
                    className="flex border-2 m-3 p-2 rounded-md shadow-xl"
                    style={{
                      borderColor:
                        charger?.[1] === "Charging"
                          ? "#16803C"
                          : charger?.[1] === "Unavailable" ||
                            charger?.[1] === "Faulted"
                          ? "red"
                          : charger?.[1] === "SuspendedEV"
                          ? "#1b9bd1"
                          : "",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: 50, width: 50 }}
                        alt="Charger"
                        src="https://thumbs.dreamstime.com/b/electric-vehicle-charging-station-public-space-usage-clipping-path-available-68138907.jpg"
                      />
                    </div>
                    <div>
                      <p className="font-bold">Charger : {charger?.[0]}</p>
                      <p className="text-sm font-semibold">
                        Status :{" "}
                        <span
                          style={{
                            color:
                              charger?.[1] === "Charging"
                                ? "#16803C"
                                : charger?.[1] === "Unavailable" ||
                                  charger?.[1] === "Faulted"
                                ? "red"
                                : charger?.[1] === "SuspendedEV"
                                ? "#1b9bd1"
                                : "",
                          }}
                        >
                          {charger?.[1]}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
        {suspendedEvChargers
          ? suspendedEvChargers?.map((charger) => {
              return (
                <>
                  <div
                    className="flex border-2 m-3 p-2 rounded-md shadow-xl"
                    style={{
                      borderColor:
                        charger?.[1] === "Charging"
                          ? "#1ff702"
                          : charger?.[1] === "Unavailable" ||
                            charger?.[1] === "Faulted"
                          ? "red"
                          : charger?.[1] === "SuspendedEV"
                          ? "#1b9bd1"
                          : "",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: 50, width: 50 }}
                        alt="Charger"
                        src="https://thumbs.dreamstime.com/b/electric-vehicle-charging-station-public-space-usage-clipping-path-available-68138907.jpg"
                      />
                    </div>
                    <div>
                      <p className="font-bold">Charger : {charger?.[0]}</p>
                      <p className="text-sm font-semibold">
                        Status :{" "}
                        <span
                          style={{
                            color:
                              charger?.[1] === "Charging"
                                ? "green"
                                : charger?.[1] === "Unavailable" ||
                                  charger?.[1] === "Faulted"
                                ? "red"
                                : charger?.[1] === "SuspendedEV"
                                ? "#1b9bd1"
                                : "",
                          }}
                        >
                          {charger?.[1]}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
        {finishingChargers
          ? finishingChargers?.map((charger) => {
              return (
                <>
                  <div
                    className="flex border-2 m-3 p-2 rounded-md shadow-xl"
                    style={{
                      borderColor:
                        charger?.[1] === "Charging"
                          ? "#1ff702"
                          : charger?.[1] === "Unavailable" ||
                            charger?.[1] === "Faulted"
                          ? "red"
                          : charger?.[1] === "SuspendedEV"
                          ? "#1b9bd1"
                          : "",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: 50, width: 50 }}
                        alt="Charger"
                        src="https://thumbs.dreamstime.com/b/electric-vehicle-charging-station-public-space-usage-clipping-path-available-68138907.jpg"
                      />
                    </div>
                    <div>
                      <p className="font-bold">Charger : {charger?.[0]}</p>
                      <p className="text-sm font-semibold">
                        Status :{" "}
                        <span
                          style={{
                            color:
                              charger?.[1] === "Charging"
                                ? "green"
                                : charger?.[1] === "Unavailable" ||
                                  charger?.[1] === "Faulted"
                                ? "red"
                                : charger?.[1] === "SuspendedEV"
                                ? "#1b9bd1"
                                : "",
                          }}
                        >
                          {charger?.[1]}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
        {unavailableChargers
          ? unavailableChargers?.map((charger) => {
              return (
                <>
                  <div
                    className="flex border-2 m-3 p-2 rounded-md shadow-xl"
                    style={{
                      borderColor:
                        charger?.[1] === "Charging"
                          ? "#1ff702"
                          : charger?.[1] === "Unavailable" ||
                            charger?.[1] === "Faulted"
                          ? "#B91C1B"
                          : charger?.[1] === "SuspendedEV"
                          ? "#1b9bd1"
                          : "",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: 50, width: 50 }}
                        alt="Charger"
                        src="https://thumbs.dreamstime.com/b/electric-vehicle-charging-station-public-space-usage-clipping-path-available-68138907.jpg"
                      />
                    </div>
                    <div>
                      <p className="font-bold">Charger : {charger?.[0]}</p>
                      <p className="text-sm font-semibold">
                        Status :{" "}
                        <span
                          style={{
                            color:
                              charger?.[1] === "Charging"
                                ? "green"
                                : charger?.[1] === "Unavailable" ||
                                  charger?.[1] === "Faulted"
                                ? "#B91C1B"
                                : charger?.[1] === "SuspendedEV"
                                ? "#1b9bd1"
                                : "",
                          }}
                        >
                          {charger?.[1]}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
        {availableChargers
          ? availableChargers?.map((charger) => {
              return (
                <>
                  <div
                    className="flex border-2 m-3 p-2 rounded-md shadow-xl"
                    style={{
                      borderColor:
                        charger?.[1] === "Charging"
                          ? "#1ff702"
                          : charger?.[1] === "Unavailable" ||
                            charger?.[1] === "Faulted"
                          ? "red"
                          : charger?.[1] === "SuspendedEV"
                          ? "#1b9bd1"
                          : "",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: 50, width: 50 }}
                        alt="Charger"
                        src="https://thumbs.dreamstime.com/b/electric-vehicle-charging-station-public-space-usage-clipping-path-available-68138907.jpg"
                      />
                    </div>
                    <div>
                      <p className="font-bold">Charger : {charger?.[0]}</p>
                      <p className="text-sm font-semibold">
                        Status :{" "}
                        <span
                          style={{
                            color:
                              charger?.[1] === "Charging"
                                ? "green"
                                : charger?.[1] === "Unavailable" ||
                                  charger?.[1] === "Faulted"
                                ? "red"
                                : charger?.[1] === "SuspendedEV"
                                ? "#1b9bd1"
                                : "",
                          }}
                        >
                          {charger?.[1]}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    </>
  );
}

export default ShowAllChargersScree;
