import React, { useState, useEffect } from "react";
import LayoutComponent from "../../Components/LayoutComponent";
import { Select } from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Table } from "antd";
import LoadingComponent from "../../Components/LoadingComponent";

// import { DatePicker } from "antd";
// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const Option = Select.Option;

const OngoingBookingsScreen = () => {
  const [bookings, setBookings] = useState([]);
  const [formatedBookings, setformatedBookings] = useState([]);
  const [chargerStatuses, setChargerStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState();

  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [filterSet, setfilterSet] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState();

  const [wsGlobal, setWsGlobal] = React.useState("");

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
              // console.log("EnteredNewMessage");
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  const columns = [
    {
      title: "BookingId",
      dataIndex: "transactionId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "Name",
      dataIndex: "userName",
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
    },
    // {
    //   title: "IdTag",
    //   dataIndex: "idTag",
    // },
    // {
    //   title: "Charger Name",
    //   dataIndex: "chargerName",
    // },
    {
      title: "Start Time",
      dataIndex: `startTime`,
    },
    {
      title: "End in (Hours)",
      dataIndex: "settedTimePeriod",
    },
    // {
    //   title: "Blocked Amount",
    //   dataIndex: "blockedAmount",
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.blockedAmount - b.blockedAmount,
    // },
    {
      title: "Status",
      dataIndex: "status",
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 100,
    //   render: (text, record) =>
    //     record.status === "Charging" ? (
    //       <a className="ml-4" href="javascript:;">
    //         <i
    //           style={{ color: "blue", fontSize: 20 }}
    //           class="fa-solid fa-circle-stop"
    //         ></i>
    //       </a>
    //     ) : record.status === "SuspendedEV" ? (
    //       <a className="ml-4" href="javascript:;">
    //       <i
    //         style={{ color: "blue", fontSize: 20 }}
    //         class="fa-solid fa-unlock-keyhole"
    //       ></i></a>
    //     ) : record.status === "Preparing" ? (
    //       <i
    //         style={{ color: "blue", fontSize: 20 }}
    //         class="fa-solid fa-circle-play"
    //       ></i>
    //     ) : (
    //       "No Action"
    //     ),
    // },
    // {
    //   title: "Paid Amount",
    //   dataIndex: "paidCredits",
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.paidCredits - b.paidCredits,
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   filters: [
    //     {
    //       text: "London",
    //       value: "London",
    //     },
    //     {
    //       text: "New York",
    //       value: "New York",
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    //   sorter: (a, b) => a.address.length - b.address.length,
    //   sortDirections: ["descend", "ascend"],
    // },
  ];

  useEffect(() => {
    getOngoingBookings();
  }, []);

  const getOngoingBookings = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/RWA/get/ongoingBookings`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setBookings(res?.data?.bookings);
        formatBookingData(res?.data?.bookings);
      }
      // setLoading(false)
    } catch (error) {
      console.log(error);
      // setLoading(false)
    }
  };

  const formatBookingData = async (bookings) => {
    setLoading(true);
    try {
      await bookings?.map((booking) => {
        if (booking?.startTime) {
          booking.startTime =
            booking.startTime.split(" ")[1] +
            "/" +
            booking.startTime.split(" ")[2] +
            "/" +
            booking.startTime.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.startTime.split(" ")[4]);

          booking.status =
            chargerStatuses?.get(booking?.chargerName) || "Need Refresh";
        }
      });
      setLoading(false);
      console.log(bookings);
      await setUnchangedFormatedTransactions(bookings);
      await setformatedBookings(bookings);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const filterData = async () => {
    try {
      setLoading(true);
      if (selectedCharger) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((charger) => {
          if (charger?.chargerName === selectedCharger) {
            resultedArray.push(charger);
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ongoing Bookings
        </p>
        <div style={{ position: "absolute", right: 120, marginTop: 20 }}>
          <a
            onClick={() => {
              getOngoingBookings();
              setfilterSet(false);
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Refresh
          </a>
        </div>
      </div>

      <div className="flex justify-between mt-12">
        {filterSet ? (
          <a
            onClick={() => {
              getOngoingBookings();
              setfilterSet(false);
              setSelectedCharger(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto" style={{ maxHeight: 500 }}>
        <Table columns={columns} dataSource={formatedBookings} />
      </div>
    </div>
  );
};
export default OngoingBookingsScreen;
