export const BaseUrl = "https://acdcelectric.in/api/v1"

export const websocketConnectionUrl =
  'wss://acdcelectric.in';

// "http://ec2-3-111-82-158.ap-south-1.compute.amazonaws.com:9099/api/v1"

// "https://acdcelectric.in/api/v1"

//  "http://localhost:9099/api/v1";
