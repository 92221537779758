import React, { useState, useEffect } from "react";
import { DatePicker, Table } from "antd";
import { Select } from "antd";
import axios from "axios";
import LoadingComponent from "../../Components/LoadingComponent";
import { BaseUrl } from "../../static/BaseUrl";
import exportFromJSON from "export-from-json";

const Option = Select.Option;
const RWAPaymentsDetailsScreen = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const [makePaymentModalOpen, setMakePaymentModalOpen] = useState(false);
  const [seletedLocation, setSelectedLocation] = useState();

  const [statusFilter, setStatusFilter] = useState();
  const [loading, setLoading] = useState(true);

  const [formatedData, setFormatedData] = useState([]);

  const [filterSet, setfilterSet] = useState(false);

  const downloadData = async (data) => {
    const fileName = `MonthlyElectricityBilling-${selectedMonth}-${selectedYear}`;

    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  const onMonthChange = async (date) => {
    try {
      const dateString = date?.toString();
      let month = dateString?.split(" ")[2].trim();
      let year = dateString?.split(" ")[3].trim();

      setSelectedMonth(month);
      setSelectedYear(year);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentData = async () => {
    try {
      setLoading(true);
      if (!selectedMonth || !selectedYear) {
        // Create a new Date object
        var currentDate = new Date().toString();
        let month = currentDate?.split(" ")[1].trim();
        let year = currentDate?.split(" ")[3].trim();
        // console.log(month, year);
        const res = await axios.post(
          `${BaseUrl}/get/monthly/rwa/payments/data`,
          {
            token: localStorage.getItem("token"),
            month,
            year,
          }
        );
        if (res?.data?.success) {
          formatBookingData(res?.data?.details);
          setLoading(false);
        }
      } else {
        const res = await axios.post(
          `${BaseUrl}/get/monthly/rwa/payments/data`,
          {
            token: localStorage.getItem("token"),
            month: selectedMonth,
            year: selectedYear,
          }
        );
        if (res?.data?.success) {
          formatBookingData(res?.data?.details);
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentData();
  }, []);

  function convertTo12HourFormat(timeString) {
    const formattedTime = `${timeString?.split(" ")[0]} ${
      timeString?.split(" ")[1]
    } ${timeString?.split(" ")[2]} ${timeString?.split(" ")[3]}`;
    return formattedTime;
  }

  const [unchangedData, setUnchangedData] = useState();

  function maskAccountNumber(accountNumber) {
    // Convert accountNumber to string (if it's not already)
    let strAccountNumber = String(accountNumber);

    // Check if the account number has at least 4 digits
    if (strAccountNumber.length >= 4) {
      // Extract the last 4 characters
      let lastFourDigits = strAccountNumber.slice(-4);
      // Mask the rest of the digits with 'X'
      let maskedString =
        "x".repeat(strAccountNumber.length - 4) + lastFourDigits;
      return maskedString;
    } else {
      // Handle case where account number is less than 4 digits (if necessary)
      return strAccountNumber; // Or handle as per your requirements
    }
  }

  const formatBookingData = (data) => {
    setLoading(true);
    try {
      let resultedData = [];
      data?.map((data) => {
        if (data?.status === "Paid") {
          let Time = convertTo12HourFormat(data?.time);
          data.time = Time;
          let accountNumber = maskAccountNumber(
            data?.accountDetails?.accountNumber
          );
          data.accountNumber = accountNumber;
          resultedData.push(data);
        } else {
          data.paymentId = "-";
          data.accountNumber = "-";
          data.time = "-";
          resultedData.push(data);
        }
      });
      setUnchangedData(resultedData);
      setFormatedData(resultedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = async () => {
    setLoading(true);
    try {
      if (statusFilter === "All") {
        setLoading(false);
        return setFormatedData(unchangedData);
      }
      const resultedData = [];
      unchangedData?.map((data) => {
        if (data?.status === statusFilter) {
          resultedData?.push(data);
        }
      });
      setFormatedData(resultedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Location",
      dataIndex: "locationName",
      width: 200,
    },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      width: 120,
      // fixed: "left",
    },
    {
      title: "Total Units Consumed (kWh)",
      dataIndex: "unitsSold",
      width: 120,
    },
    {
      title: "Total Electricity Amount (Rs)",
      dataIndex: "billAmount",
      width: 120,
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: `status`,
    //   width: 120,
    // },
    {
      title: "Account No.",
      dataIndex: "accountNumber",
      width: 120,
    },
    {
      title: "UTR No.",
      dataIndex: "paymentId",
      width: 120,
    },
    {
      title: "Date of Payment",
      dataIndex: "time",
      width: 180,
    },
    {
      title: "Status",
      key: "operation",
      width: 150,
      render: (text, record) =>
        record.status === "Paid" ? (
          <div className="mx-7">
            <p className="text-center py-2 bg-green-700 rounded-lg text-white">
              Paid
            </p>
          </div>
        ) : (
          <a
            onClick={() => {
              setMakePaymentModalOpen(true);
              setSelectedLocation(record);
            }}
            className="ml-4"
            href="javascript:;"
          >
            <div className="mx-7">
              <p className="text-center cursor-pointer py-2 bg-red-700 rounded-lg text-white">
                Click to pay
              </p>
            </div>
          </a>
        ),
    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          RWA Payments Details
        </p>
      </div>

      {/* Filter Data */}

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 5 }}>Select Month</p>
          <DatePicker
            className="ml-4"
            style={{ width: 250 }}
            placeholder="Select month"
            onChange={onMonthChange}
            picker="month"
          />
          <a
            onClick={() => {
              getPaymentData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 ml-2 border rounded-md bg-slate-500 text-white"
          >
            Get Data
          </a>
        </div>
      </div>

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Status</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select transaction status"
            optionFilterProp="children"
            onChange={(value) => setStatusFilter(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={"All"}>All</Option>
            <Option value={"Pending"}>Pending</Option>
            <Option value={"Paid"}>Paid</Option>
          </Select>
        </div>
        <div className="flex justify-center">
          <a
            onClick={() => {
              filterData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedData}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
  //   <DatePicker placeholder="Current month" onChange={onMonthChange} picker="month" />;
};

export default RWAPaymentsDetailsScreen;
