import React, { useState, useEffect } from "react";
import { Button, Modal, Select, message } from "antd";
import { websocketConnectionUrl } from "../../../static/BaseUrl";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [selectedResetType, setselectedresetType] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();

  const handleSendRequest = async (socket) => {
    try {
      setTriggerMessageModalOpen(true);
      let sendData = {
        From: "BD8746G645VWU83",
        To: `${selectedCharger}`,
        message: [
          2,
          "ResetCharger",
          "Reset",
          {
            type: selectedResetType,
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === "ResetCharger"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
      }
      // else if (
      //   rawResponseData &&
      //   rawResponseData.split("*")[2]?.split('"')[3] === 'SendLocalListRequest'
      // ) {
      //   setResponseMessage(rawResponseData.split("*")[2]);
      // }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);

      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const chargersMap = new Map();
            const array = JSON.parse(arrayString);
            setCharegrStatusArray(array);
            array.map((charger) => {
              return chargersMap.set(charger[0], charger[1]);
            });
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Close
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Reset Charger</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>
      <p className="font-semibold text-xl mb-1">CMS Operation : Reset</p>
      <p className="text-slate-600">
        The Central System SHALL send a Reset.req PDU for requesting a Charge
        Point to reset itself. The Central System can request a hard or a soft
        reset. Upon receipt of a Reset.req PDU, the Charge Point SHALL respond
        with a Reset.conf PDU. The response PDU SHALL include whether the Charge
        Point will attempt to reset itself. After receipt of a Reset.req, The
        Charge Point SHALL send a StopTransaction.req for any ongoing
        transaction before performing the reset. If the Charge Point fails to
        receive a StopTransaction.conf form the Central System, it shall queue
        the StopTransaction.req. At receipt of a soft reset, the Charge Point
        SHALL stop ongoing transactions gracefully and send StopTransaction.req
        for every ongoing transaction. It should then restart the application
        software (if possible, otherwise restart the processor/controller). At
        receipt of a hard reset the Charge Point SHALL restart (all) the
        hardware, it is not required to gracefully stop ongoing transaction. If
        possible the Charge Point sends a StopTransaction.req for previously
        ongoing transactions after having restarted and having been accepted by
        the Central System via a BootNotification.conf. This is a last resort
        solution for a not correctly functioning Charge Points, by sending a
        "hard" reset, (queued) information might get lost.
      </p>
      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              if (charger[1] !== "Unavailable") {
                return <option value={charger[0]}>{charger[0]}</option>;
              }
            })}
        </Select>

        {selectedCharger ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select Reset type"}
            onChange={(value) => setselectedresetType(value)}
          >
            <option value="Soft">Soft</option>
            <option value="Hard">Hard</option>
          </Select>
        ) : (
          ""
        )}
      </div>
      {selectedResetType ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
